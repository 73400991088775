<template>
    <transition name="fade-transition" mode="out-in">
        <router-view></router-view>
    </transition>
</template>
<script>
export default {
    mounted(){
        this.$store.commit('toolbar/showToolbar', false)
    },
    beforeDestroy() {
        this.$store.commit('toolbar/showToolbar', true)
    },
}
</script>